<template>
  <div style="position:relative; height:calc(100vh - 55px);">
    <button @click="downloadPdfReport(url_data,dashboard,user_name)" style="background-color: var(--primary_color);font-size: 18px;color:#ffffff; position: relative;top:5px;float: right;right: 25px;padding: 10px;border:0 none; border-radius: 33px;">Download as PDF</button>
    <iframe :src="url_data" frameborder="0" allowfullscreen
      style="position:relative;top:0;left:0;width:100%;height:100%;"></iframe>
  </div>
</template>
<script>
 import commonFunction from "../../mixin/commonFunction";
export default {
  name: "program_overview_alchemer_reports",
  mounted() {
    this.report_id = this.$route.params.url;
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    document.body.classList.add("viewreporthidden");
    this.getAlchemerReport(this.report_id);
  },
   mixins: [commonFunction],
  data() {
    return {
      report_id: null,
      url_data: null,
      user_name: null,
      type_of_assessment: null,
      dashboard:'Completed Self-Assessment',
    };
  },
  methods: {
    getAlchemerReport(id) {
      let config = {
        method: "post",
        url:
          process.env.VUE_APP_API_URL +
          "/api/get-program-overview-report",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: {reportId : this.encodeAPIRequest(id)},
      };
      this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = this.decodeAPIResponse(data.data);
          this.url_data = decodedJson.summary_report;
          this.user_name = decodedJson.user_name;
          this.type_of_assessment = decodedJson.type_of_assessment;
          this.dashboard = 'Completed '+decodedJson.type_of_assessment;
          if(this.$router.currentRoute.name == 'custom_search_reports'){
            this.dashboard = 'Custom Search';
          }
        })
        .catch(({ response }) => {
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
          this.$router.push({ name: "404" });
        });
    },
  },
};
</script>